import React from "react"
import PageRoot from "../../components/PageRoot"
import { Button, Result } from "antd"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const SendMassageSuccessPage = () => {
  const { t } = useTranslation()
  return (
    <PageRoot title={t("label:messageSent")} showTitle>
      <Result
        status="success"
        title={t("message:helpMessageSentWithSuccess")}
        extra={[
          <Button key="home">
            <Link to={`/`}>{t("button:goHome")}</Link>
          </Button>,
        ]}
      />
    </PageRoot>
  )
}

export default SendMassageSuccessPage
